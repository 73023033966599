import React, { useEffect, useState } from 'react'
import backendApi from '../api/backendApi'
import { ApiTokens, getApiTokensRequest } from '../interfaces/AppInterfaces'
import { Button, Divider, Flex, Form, Input, Modal, notification, Space, Table, Tag, Typography } from 'antd'
import dayjs from 'dayjs';
import { FrownOutlined, PlusOutlined } from '@ant-design/icons';
import sirumatekLogo from '../assets/sirumatekLogo.png';

const DigitalEmiterApiPage = () => {
  
    const [apiKeys, setApiKeys] = useState<ApiTokens[]>()
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [registerNewApiKeyModal, setRegisterNewApiKeyModal] = useState<boolean>(false)
    const [name, setName] = useState<string>('')
    const [description, setDescription] = useState<string>('')
    const [api, contextHolder] = notification.useNotification();

    const getApiKeyes = async () => {
        
        const myApiKeys = await backendApi.get<getApiTokensRequest>('/getApiTokens');
        setApiKeys(myApiKeys.data.data[0]);
        setIsLoading(false);
    }

    const createApiKeys = async () =>{
        
        const data = {
            description:description,
            name:name
        }
        const saveApiToken = await backendApi.post('/generateApiToken',data).then(res=>{
            openSuccesNotification();
            setName('')
            setDescription('')
            setRegisterNewApiKeyModal(false)

        }).catch(err=>{
             
            openErrorNotification(err.response.data.errors);

        });
        
    }

    const openErrorNotification = (err:JSON) => {
        console.log(err);
        const errors = JSON.stringify(err);
        api.open({
          message: 'No se Registro el Emisor',
          description:
            'Ha ocurrido un Error:  '+errors,
          icon: <FrownOutlined style={{ color: '#47b5bd' }} />,
        });
    };

    const openSuccesNotification = () => {
        api.open({
          message: 'Se Registro Con Exito el Token',
          description:
            'Se ha registrado con exito el Token',
          icon: <PlusOutlined style={{ color: '#47b5bd' }} />,
        });
      };

    const columns = [
        {
            title: 'Nombre',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Descripcion',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Estatus',
            dataIndex: 'status',
            key: 'status',
            render: (text:number) =>{
                switch (text) {
                    case 1:
                        return <Tag color='green'>Activa</Tag>
                        break;
                    case 2:
                        return <Tag color='red'>Inactiva</Tag>
                        break
                    default:
                        return <Tag color='grey'>N/A</Tag>
                        break;
                }
            } 
        },
        {
            title: 'Fecha de Creacion',
            dataIndex: 'created_at',
            key: 'created_at',
        },
    ]

    useEffect(() => {
        getApiKeyes()
    }, [])
    

    return (
    <Flex justify="center" vertical gap="middle">
        {contextHolder}
        <Flex style={{width:'100%'}} justify='space-between' >
            <Typography.Title>LLaves de Transmision</Typography.Title>
            <Button type="primary" style={{background:'#47b5bd', alignSelf:'center',width:200}} onClick={()=>setRegisterNewApiKeyModal(true)}>
                <PlusOutlined /> Nueva LLave
            </Button>  
       </Flex>
       <Table columns ={columns} dataSource={apiKeys} scroll={{ x: 1}} loading={isLoading}/>
       <Modal open={registerNewApiKeyModal} footer={null}  style={{ top: 20 }} width={900} onCancel={()=>setRegisterNewApiKeyModal(false)}>
       <Flex align="middle" justify="center" vertical>
                <Flex align="middle" justify="center" vertical>
                    <img
                        src={sirumatekLogo}
                        width={238}
                        height={42}
                        alt="Conpany Logo"
                        style={{alignSelf:'center'}}
                        />
                    <Typography.Text style={{fontSize:14, color: 'rgba(0, 0, 0, 0.4)',alignSelf:'center',marginTop:5}}>Sistema de Imprenta Digital </Typography.Text>
                </Flex>
                    <Typography.Text style={{fontSize:18, fontWeight:'bold'}}>Registrar Api Token</Typography.Text>  
                    <Divider/>
                    <Form layout='vertical' style={{width:'100%'}} onFinish={createApiKeys}>
                        <Form.Item label="Nombre" name="name" rules={[{ required: true, message: 'Ingrese el Nombre del Token' }]} style={{width:'100%'}}>
                            <Input size="large" placeholder="Nombre del Token" onChange={e => setName(e.target.value)}/>
                        </Form.Item>
                        <Form.Item label="Descripcion" name="descripcion" rules={[{ required: true, message: 'Ingrese la Descripcion' }]} style={{width:'100%'}}>
                            <Input size="large" placeholder="Descripcion del Token" onChange={e => setDescription(e.target.value)}/>
                        </Form.Item>
                        <Flex align="middle" justify='space-between' >
                            <Form.Item>
                                <Button type="primary" htmlType="submit" style={{background:'#47b5bd'}}>
                                    Registrar Api Token
                                </Button>
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" style={{background:'#ff4d4f'}} onClick={()=>setRegisterNewApiKeyModal(false)}>
                                    Cancelar
                                </Button>
                            </Form.Item>
                    </Flex>
                    </Form>
            </Flex>
       </Modal>
    </Flex>
  )
}

export default DigitalEmiterApiPage

           
       