import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import backendApi from '../api/backendApi';
import { Series, UserDetailsRequest } from '../interfaces/AppInterfaces';
import { Card, Flex, Image, Table, Tag, Typography } from 'antd';

const UserDetailsPage = () => {

  const { id } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [error, setError] = useState<string>()

  const [rifCopy, setRifCopy] = useState<string>()
  const [logo, setLogo] = useState<string>()
  const [rifNumber, setRifNumber] = useState<string>()
  const [taxAddress, setTaxAddress] = useState<string>()
  const [comercialName, setComercialName] = useState<string>()
  const [officialName, setOfficialName] = useState<string>()
  const [name, setName] = useState<string>()
  const [email, setEmail] = useState<string>()
  const [docId, setdocId] = useState<string>()
  const [mobilePhone, setMobilePhone] = useState<string>()
  const [status, setStatus] = useState<number>()
  const [role, setRole] = useState<string>()
  const [permissions, setPermissions] = useState<string[]>()
  const [billSeries, setBillSeries] = useState<Series[]>()
  const [creditNoteSeries, setCreditNoteSeries] = useState<Series[]>()
  const [debitNoteSeries, setDebitNoteSeries] = useState<Series[]>()
  const [digitalShippingOrderSeries, setDigitalShippingOrderSeries] = useState<Series[]>()
  
  const columnsFacturas =[
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id_factura',
    },
    {
      title: 'Numero de Serie Inicio',
      dataIndex: 'from_series',
      key: 'from_factura',
    },
    {
      title: 'Numero de Serie Fin',
      dataIndex: 'to_series',
      key: 'to_series_factura',
    },
    {
      title: 'Numero de Documento Inicio',
      dataIndex: 'from_consecutive',
      key: 'from_consecutive_factura',
    },
    {
      title: 'Numero de Documento Fin',
      dataIndex: 'to_consecutive',
      key: 'to_consecutive_series_factura',
    },
    {
      title: 'Documentos Disponibles',
      dataIndex: 'num_docs_avaible',
      key: 'num_docs_avaible_factura',
    },
    {
      title: 'Fecha Asignacion',
      dataIndex: 'date_assing',
      key: 'date_assing_factura',
    },
    {
      title: 'Ultimo Documento Asignado',
      dataIndex: 'last_doc_num',
      key: 'last_doc_num_f',
    },
  ]
  const columnsNotasCredito =[
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id_n_d',
    },
    {
      title: 'Numero de Serie Inicio',
      dataIndex: 'from_series',
      key: 'from_n_d',
    },
    {
      title: 'Numero de Serie Fin',
      dataIndex: 'to_series',
      key: 'to_series_n_d',
    },
    {
      title: 'Numero de Documento Inicio',
      dataIndex: 'from_consecutive',
      key: 'from_consecutive_n_d',
    },
    {
      title: 'Numero de Documento Fin',
      dataIndex: 'to_consecutive',
      key: 'to_consecutive_series_n_d',
    },
    {
      title: 'Documentos Disponibles',
      dataIndex: 'num_docs_avaible',
      key: 'num_docs_avaible_n_d',
    },
    {
      title: 'Fecha Asignacion',
      dataIndex: 'date_assing',
      key: 'date_assing_n_d',
    },
    {
      title: 'Ultimo Documento Asignado',
      dataIndex: 'last_doc_num',
      key: 'last_doc_num_n_c',
    },
  ]
  const columnsNotasDebito =[
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id_n_c',
    },
    {
      title: 'Numero de Serie Inicio',
      dataIndex: 'from_series',
      key: 'from_n_c',
    },
    {
      title: 'Numero de Serie Fin',
      dataIndex: 'to_series',
      key: 'to_series_n_c',
    },
    {
      title: 'Numero de Documento Inicio',
      dataIndex: 'from_consecutive',
      key: 'from_consecutive_n_c',
    },
    {
      title: 'Numero de Documento Fin',
      dataIndex: 'to_consecutive',
      key: 'to_consecutive_series_n_c',
    },
    {
      title: 'Documentos Disponibles',
      dataIndex: 'num_docs_avaible',
      key: 'num_docs_avaible_n_c',
    },
    {
      title: 'Fecha Asignacion',
      dataIndex: 'date_assing',
      key: 'date_assing_n_c',
    },
    {
      title: 'Ultimo Documento Asignado',
      dataIndex: 'last_doc_num',
      key: 'last_doc_num_n_c',
    },
  ]
  const columnsOrdenesEntrega =[
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id_o_d',
    },
    {
      title: 'Numero de Serie Inicio',
      dataIndex: 'from_series',
      key: 'from_o_d',
    },
    {
      title: 'Numero de Serie Fin',
      dataIndex: 'to_series',
      key: 'to_series_o_d',
    },
    {
      title: 'Numero de Documento Inicio',
      dataIndex: 'from_consecutive',
      key: 'from_consecutive_o_d',
    },
    {
      title: 'Numero de Documento Fin',
      dataIndex: 'to_consecutive',
      key: 'to_consecutive_series_o_d',
    },
    {
      title: 'Documentos Disponibles',
      dataIndex: 'num_docs_avaible',
      key: 'num_docs_avaible_o_d',
    },
    {
      title: 'Fecha Asignacion',
      dataIndex: 'date_assing',
      key: 'date_assing_o_d',
    },
    {
      title: 'Ultimo Documento Asignado',
      dataIndex: 'last_doc_num',
      key: 'last_doc_num_o_d',
    },
  ]

  const getUserData = async () => {

    const getUserDataRequest = await backendApi.get<UserDetailsRequest>('/getUser/'+id)
    setIsLoading(false)
    if (!getUserDataRequest.data.success) {
      setError(getUserDataRequest.data.message)
    }

    setRifCopy(getUserDataRequest.data.data.user.rif_copy)
    setLogo(getUserDataRequest.data.data.user.logo)
    setRifNumber(getUserDataRequest.data.data.user.rif_number)
    setTaxAddress(getUserDataRequest.data.data.user.tax_address)
    setComercialName(getUserDataRequest.data.data.user.comercial_name)
    setOfficialName(getUserDataRequest.data.data.user.official_name)
    setName(getUserDataRequest.data.data.user.name)
    setEmail(getUserDataRequest.data.data.user.email)
    setdocId(getUserDataRequest.data.data.user.doc_id)
    setMobilePhone(getUserDataRequest.data.data.user.mobile_phone)
    setStatus(getUserDataRequest.data.data.user.status)
    setBillSeries(getUserDataRequest.data.data.user.billSeries)
    setCreditNoteSeries(getUserDataRequest.data.data.user.creditNoteSeries)
    setDebitNoteSeries(getUserDataRequest.data.data.user.debitNoteSeries)
    setDigitalShippingOrderSeries(getUserDataRequest.data.data.user.shippingOrderSeries)
    setPermissions(getUserDataRequest.data.data.permissions)
  }


  useEffect(() => {
    getUserData()
  
  }, [])
  
  return (
    <Flex justify="center" vertical gap="middle">
        <Typography.Title>Resumen de Usuario</Typography.Title>  
      <Card loading={isLoading} title={comercialName}>
        <Flex justify="space-between" gap="middle">
          <Typography.Text>R.I.F</Typography.Text>
          <Typography.Text>{rifNumber}</Typography.Text>       
        </Flex>
        <Flex justify="space-between" gap="middle">
          <Typography.Text>Nombre Oficial</Typography.Text>
          <Typography.Text>{officialName}</Typography.Text>       
        </Flex>
        <Flex justify="space-between" gap="middle">
          <Typography.Text>Direccion Fiscal</Typography.Text>
          <Typography.Text>{taxAddress}</Typography.Text>       
        </Flex>
        <Flex justify="space-between" gap="middle">
          <Typography.Text>Nombre del Responsable</Typography.Text>
          <Typography.Text>{name}</Typography.Text>       
        </Flex>
        <Flex justify="space-between" gap="middle">
          <Typography.Text>Email del Responsable</Typography.Text>
          <Typography.Text>{email}</Typography.Text>       
        </Flex>
        <Flex justify="space-between" gap="middle">
          <Typography.Text>Documento del Responsable</Typography.Text>
          <Typography.Text>{docId}</Typography.Text>       
        </Flex>
        <Flex justify="space-between" gap="middle">
          <Typography.Text>Telefono del Responsable</Typography.Text>
          <Typography.Text>{mobilePhone}</Typography.Text>       
        </Flex>
      </Card>
      <Card loading={isLoading} title={comercialName+' Documentos'}>
        <Flex justify="space-between" gap="middle">
          <Typography.Text>Logo</Typography.Text>
          <Image
            width={200}
            src={'data:image/png;base64, '+logo}
          />              
        </Flex>
        <Flex justify="space-between" gap="middle">
          <Typography.Text>R.I.F</Typography.Text>
          <Image
            width={200}
            src={'data:image/png;base64, '+rifCopy}
          />       
        </Flex>
      </Card>
      <Card loading={isLoading} title={comercialName+' Permisos'}>
        <Flex gap="4px 0" wrap>
          {
            permissions?.map(elm=>{
              return <Tag color="cyan">{elm}</Tag>
            })
          }
        </Flex>
      </Card>
      <Card loading={isLoading} title={comercialName+' Series de Documentos'}>
        <Flex justify="center" vertical gap="middle">
          <Table columns ={columnsFacturas} dataSource={billSeries} scroll={{ x: 1}} loading={isLoading} title={()=>"Series de Facturas"}/>  
          <Table columns ={columnsNotasCredito} dataSource={creditNoteSeries} scroll={{ x: 1}} loading={isLoading} title={()=>"Series de Notas de Credito"}/>  
          <Table columns ={columnsNotasDebito} dataSource={debitNoteSeries} scroll={{ x: 1}} loading={isLoading} title={()=>"Series de Notas de Debito"}/>  
          <Table columns ={columnsOrdenesEntrega} dataSource={digitalShippingOrderSeries} scroll={{ x: 1}} loading={isLoading} title={()=>"Series de Guias de Despacho"}/> 
        </Flex>
      </Card>
    </Flex>
  )
}

export default UserDetailsPage
