import React, { useState } from 'react'
import sirumatekLogo from '../assets/sirumatekLogo.png';
import { Alert, Button, Flex, Form, Input, Layout, notification, Typography } from 'antd'
import { UserOutlined, LockOutlined, KeyOutlined, FrownOutlined } from '@ant-design/icons';
import { useForm } from 'antd/es/form/Form';
import backendApi from '../api/backendApi';
import { useNavigate } from 'react-router-dom';


const ForgotPasswordPage = () => {
  
  const [errorMessage, setErrorMessage] = useState('')
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [returnedToken, setReturnedToken] = useState('');
  const [token, setToken] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [status, setStatus] = useState('Stage 1');
  const [form] = useForm();
  const [api, contextHolder] = notification.useNotification();
  const navigate = useNavigate();

  const getApiResetToken = async()=>{
    if (email!='') {
      const data = {email:email}
      const response = await backendApi.post('/forgot_password',data).then(elm =>{
        api.open({
          message: 'Se ha Enviado un Token de Verificacion al Coreero Electronico',
          description:
            'Por Favor Ingrese el Token Enviado a al Correo electronico',
          icon: <LockOutlined style={{ color: '#47b5bd' }} />,
        });
        setStatus('Stage 2')
      }).catch(err =>{
        console.log(err);
        api.open({
          message: 'Error',
          description:
            'Ha Ocurrido un Error',
          icon: <FrownOutlined style={{ color: '#47b5bd' }} />,
        });
      })
    }
  }

  const setPassword = async()=>{
    if (newPassword!='') {
      const data = {
        password_confirmation:newPassword,
        password:newPassword,
        password_token:returnedToken
      }
      const response = await backendApi.post('/new_passwords',data).then(res =>{
        api.open({
          message: 'Se ha Reiniciado con Exito Su Contraseña',
          description:
            'Se ha Reiniciado con Exito Su Contraseña Ya Puede Ingresar al Sistema',
          icon: <KeyOutlined style={{ color: '#47b5bd' }} />,
        });
        setStatus('Stage 1')
        navigate('/',{replace:true})
      }).catch(err =>{
        console.log(err);
        api.open({
          message: 'Error',
          description:
            'Ha Ocurrido un Error',
          icon: <FrownOutlined style={{ color: '#47b5bd' }} />,
        });
      })
    }
  }

  const setCheckPasswordResetToken = async()=>{
    if (token!='') {
      const data = {reset_password_code:token}
      const response = await backendApi.post('/reset_password_token',data).then(res =>{
        api.open({
          message: 'Se ha Validado el Token con Exito',
          description:
            'Se ha Validado el Token con Exito Por Favor Ingrese Su Nueva Contraseña',
          icon: <KeyOutlined style={{ color: '#47b5bd' }} />,
        });
        setStatus('Stage 3')
        setReturnedToken(res.data.data)
      }).catch(err =>{
        console.log(err);
        api.open({
          message: 'Error',
          description:
            'Ha Ocurrido un Error',
          icon: <FrownOutlined style={{ color: '#47b5bd' }} />,
        });
      })
    }
  }

  return (
    <Layout>
      {contextHolder}
    <Layout.Content>
      <Flex justify="center" style={{ height:'100vh'}} vertical gap="middle">
        <Flex align="middle" justify="center">
          <img
              src={sirumatekLogo}
              width={238}
              height={42}
              alt="Conpany Logo"
            />
        </Flex>
        <Flex align="middle" justify="center">
          <Typography.Text style={{fontSize:14, color: 'rgba(0, 0, 0, 0.4)'}}>Sistema de Imprenta Digital </Typography.Text>
        </Flex>
        <Flex align="middle" justify="center">
          <Typography.Text style={{fontSize:14, color: '#47b5bd'}}>Recuperar Contraseña </Typography.Text>
        </Flex>
        <Flex align="middle" justify="center">
          {
              errorMessage !== '' ?
              <Alert
                  message= {errorMessage}
                  type="error"
                  closable
              />
              :
              null
            }
          </Flex>
        <Flex align="middle" justify="center">
          {
            status === 'Stage 1' ?
            <>
              <Form
                name="getRecoverPasswordToken"
                onFinish={getApiResetToken}
                autoComplete="off"
                size='large' 
              >
                    <Form.Item name="email"  rules={[{ required: true, message: 'Por favor Ingrese su Email' },{type:'email', message:'Ingrese una direccion de correo electronico'}]}>
                      <Input prefix={<UserOutlined style={{color:'#f58934'}}/>} placeholder='Ingrese su Email' size="large" value={email}
                            onChange={e => setEmail(e.target.value)} disabled={isLoading}/>
                    </Form.Item>
                    <Form.Item>
                      <Button type="primary" htmlType="submit" style={{background:'#47b5bd',alignSelf:'center'}} loading={isLoading} disabled={isLoading}>
                        Recuperar Contraseña
                      </Button>
                    </Form.Item>
              </Form>
            </>
            :
            null
         }
         {
          status === 'Stage 2' ?
          <>
             <Form
                name="setRecoveryPasswordToken"
                onFinish={setCheckPasswordResetToken}
                autoComplete="off"
                size='large' 
              >
                    <Form.Item name="token"  rules={[{ required: true, message: 'Por favor Ingrese El Token Enviado a su Correo' }]}>
                      <Input prefix={<KeyOutlined style={{color:'#f58934'}}/>} placeholder='Ingrese el Token' size="large" value={email}
                            onChange={e => setToken(e.target.value)} disabled={isLoading}/>
                    </Form.Item>
                    <Form.Item>
                      <Button type="primary" htmlType="submit" style={{background:'#47b5bd',alignSelf:'center'}} loading={isLoading} disabled={isLoading}>
                        Validar Token
                      </Button>
                    </Form.Item>
              </Form>
          </>
          :
          null
         }
         {
          status === 'Stage 3' ?
          <>
            <Form
                name="setRecoveryPassword"
                onFinish={setPassword}
                autoComplete="off"
                size='large'
                
              >
                <Form.Item name="password" rules={[{ required: true, message: 'Ingrese la Razon Social' }]} style={{width:'100%'}}>
                  <Input size="large" placeholder="Contraseña" onChange={e => setNewPassword(e.target.value)}  type='password' />
                </Form.Item>
                <Form.Item name="confirmPassword" 
                  rules={[
                      { required: true, message: 'Confirme su Contraseña' },
                      ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error('Las Contraseñas Ingresadas no Coinciden!'));
                          },
                        }),
                  ]} 
                  style={{width:'100%'}}>
                    <Input size="large" placeholder="Confirmar Contraseña" type='password'/>
                  </Form.Item>
                    <Form.Item>
                      <Button type="primary" htmlType="submit" style={{background:'#47b5bd',alignSelf:'center'}} loading={isLoading} disabled={isLoading}>
                        Nueva Contraseña
                      </Button>
                    </Form.Item>
              </Form>      

          </>
          :
          null
         }
        </Flex>
      </Flex> 
    </Layout.Content>
  </Layout>
  )
}

export default ForgotPasswordPage
