import { Flex, Space, Table, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import backendApi from '../api/backendApi'
import { DigitalBill, GetDigitalBillsRequest } from '../interfaces/AppInterfaces'
import Link from 'antd/es/typography/Link'

const DigitalEmiterCreditNotePage = () => {
  
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [ digitalCreditNotes, setDigitalCreditNotes] = useState<DigitalBill[]>()

    const getDigitalCreditNotes = async () => {
        const getDigitalBillsRequest = await backendApi.get<GetDigitalBillsRequest>('/getEmitersCreditNotes')
        setDigitalCreditNotes(getDigitalBillsRequest.data.data[0])
        setIsLoading(false)
    }
    
    const columns =[
        {
            title: 'Numero de Control',
            dataIndex: 'control_num',
            key: 'control_num',
        },
        {
            title: 'Numero del Documento',
            dataIndex: 'doc_num',
            key: 'doc_num',
        },
        {
            title: 'Documento a Enmendar',
            dataIndex: 'doc_num',
            key: 'doc_num',
        },
        {
            title: 'Nombre del Cliente',
            dataIndex: 'client_full_name',
            key: 'client_full_name',
        },
        {
            title: 'Email del Cliente',
            dataIndex: 'client_email',
            key: 'client_email',
        },
        {
            title: 'Total de Nota de Credito',
            dataIndex: 'total_w_taxes',
            key: 'total_w_taxes',
        },
        {
            title: 'Total de Impuesto',
            dataIndex: 'total_tax',
            key: 'total_tax',
        },
        {
            title: 'Ver',
            key: 'watch',
            //@ts-ignore
            render: (_, record) => (
                <Space size="middle">
                  <Link onClick={()=>downloadCreditNotelXML(record.id)}>XML</Link>
                  <Link onClick={()=>getCreditNotelPDF(record.id)}>PDF</Link>
                </Space>
              ),
        },
    ]
    const downloadCreditNotelXML = async (id:string) => {
      
        const response = await backendApi.get("getDigitalBillXML/"+id,{responseType: 'blob'})
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        const fileName = 'NCTO_'+id+'.xml';
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
    }

    const getCreditNotelPDF = async (id:string)=>{
        const response = await backendApi.get("getDigitalBillPDF/"+id)
        window.open(response.data.data[0]); 
    }
    useEffect(() => {   
        getDigitalCreditNotes()
    }, [])
    
    return (
    <Flex justify="center" vertical gap="middle">
      <Typography.Title>Notas de Credito</Typography.Title>  
        <Table columns ={columns} dataSource={digitalCreditNotes} scroll={{ x: 1}} loading={isLoading}/>
    </Flex>
  )
}

export default DigitalEmiterCreditNotePage
