import React, { createContext, useEffect, useReducer } from 'react';
import backendApi from '../api/backendApi';
import { LoginRequest, User, UserLoginResponse } from '../interfaces/AppInterfaces';
import { authReducer, AuthState } from './AuthReducer';

type AuthContextProps = {
    errorMessage:string,
    token:null|string,
    user:User|null,
    status:'checking'|'autenticated'|'not-autenticated',
    signIn: (loginData:LoginRequest) => void,
    signLogOut: () => void,
    removeError: () => void,
    checkToken: () => void,
}

const authInitialState: AuthState = {
    status:'checking',
    user: null,
    token: null,
    errorMessage:''
}

export const AuthContext = createContext({} as AuthContextProps);

export const AuthProvider = ({children}:any) => {
    
    const [state, dispatch] = useReducer(authReducer, authInitialState);

    
    useEffect(() => {
        checkToken();
    }, [])
    
    const checkToken = async()=>{
        const token =  localStorage.getItem('token');
        
        if (!token ) {
            console.log("Token: "+token);
            await signLogOut();
            return dispatch({ type: 'notAutenticated' });
        }
        else{
            dispatch({type:'checking', payload:'autenticated'})
        }
        
        
    }

    const signIn= async({ email, password }:LoginRequest) =>{
        try {
            
            const  data = await backendApi.post<UserLoginResponse>('/login',{email, password});
           
            dispatch({
                type:'signUp',
                payload:{
                    token: data.data.data.token,
                    user: data.data.data.user,

                }
            });

            localStorage.setItem('token',data.data.data.token);
            localStorage.setItem('user',JSON.stringify(data.data.data.user));
            localStorage.setItem('role',JSON.stringify(data.data.data.user.role));
        } catch (error) {
            console.log(error);
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            // @ts-ignore
            dispatch({type:'addError',payload: error.response.data.msg || 'Informacion Incorrecta'});            
        }
    };

    const signLogOut= async() =>{
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('role');
        dispatch({type:'logOut'});
    };
    
    const removeError= () =>{
        dispatch({type:'removeError'});
    };

    return (
    
     <AuthContext.Provider value={{
            ...state,
            signIn,
            signLogOut,
            removeError,
            checkToken,
        }}>
            {children}
        </AuthContext.Provider>
  )
}

export default AuthContext