import React, { useContext, useEffect } from 'react';
import {Navigate, useNavigate} from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { ConfigProvider, Divider, Layout, Menu} from 'antd';
import {WarningOutlined, UploadOutlined, NotificationOutlined, FormOutlined, UserOutlined, DashboardOutlined, ContactsOutlined, FileTextOutlined, LogoutOutlined, ApiOutlined} from '@ant-design/icons';
import sirumatekLogo from '../assets/sirumatekLogo.png'; 
import locale from 'antd/locale/es_ES';
import dayjs from 'dayjs';
import 'dayjs/locale/es';



  
 export const ProtectedRoute = ({ children }:any) => {
    const {status, signLogOut} = useContext(AuthContext)
    const { Header, Content, Sider } = Layout;
    const role = localStorage.getItem('role');
    const navigate = useNavigate();
    dayjs.locale('zh-cn');

    const items = [
        {
          key: '1',
          icon: <DashboardOutlined />,
          label: 'Resumen',
          onClick:()=> navigate('/dashboard') 
        },
        {
          key: '2',
          icon: <UserOutlined />,
          label: 'Usuarios',
          onClick:()=> navigate('/users') 
        },
        {
          key: '3',
          icon: <ContactsOutlined />,
          label: 'Emisores Digitales',
          onClick:()=> navigate('/DigitalEmiters') 
        },
        {
          key: 'sub1',
          label: 'Roles y Permiso',
          icon: <FormOutlined />,
          children: [
            {
              key: 'subA2',
              label: 'Roles',
              onClick:()=> navigate('/roles')
            },
            {
              key: 'subA3',
              label: 'Permisos',
              onClick:()=> navigate('/permissions')
            }
          ],
        },
        {
            key: '4',
            icon: <FileTextOutlined />,
            label: 'Respaldos',
            onClick:()=> navigate('/backups')
        },
        {
            key: '5',
            icon: <NotificationOutlined />,
            label: 'Notificaciones',
            onClick:()=> navigate('/notifications')
        },
    ];

    const emiterItems =[
        {
            key: '1',
            icon: <DashboardOutlined />,
            label: 'Resumen',
            onClick:()=> navigate('/dashboardEm') 
        },
        {
            key: 'sub1',
            label: 'Consulta de Formas',
            icon: <FormOutlined />,
            children: [
              {
                key: 'sub2',
                label: 'Facturas',
                onClick:()=> navigate('/digitalBillsEm')
              },
              {
                key: 'sub3',
                label: 'Notas de Credito',
                onClick:()=> navigate('/digitalCNEm')
              },
              {
                key: 'sub4',
                label: 'Notas de Debito',
                onClick:()=> navigate('/digitalDNEm')
              },
              {
                key: 'sub5',
                label: 'Ordenes de Entrega',
                onClick:()=> navigate('/digitalSO')
              }
            ],
          },
        {
            key: '2',
            icon: <UploadOutlined />,
            label: 'Cargas Masivas',
            onClick:()=> navigate('/massivesUploads') 
        },
        {
          key: '3',
          icon: <ApiOutlined />,
          label: 'Api',
          onClick:()=> navigate('/digitalApiKeysEm') 
      },
    ]

    const itemsLogout = [
        {
            key: '99',
            icon: <LogoutOutlined style={{color:'red'}}/>,
            label: 'Cerrar Sesion',
            onClick: signLogOut
          }
    ]
   
      
    
     
    useEffect(() => {
      if (status === 'not-autenticated') {
        navigate('/',{replace: true});
      }
    }, [status])
    
    
  
    return (
      <ConfigProvider locale={locale}>
        <Layout style={{height:'100vh' }}>
            <Sider

                style={{backgroundColor:'#FFFF'}} 
            >
                    <img
                        src={sirumatekLogo}
                        alt="Conpany Logo"
                        style={{maxWidth:'169px', width:'100%', height:'24px', margin:'16px'}}
                    />  
                <Menu mode="inline" items={ role === '"Admin"' ?   items : emiterItems} defaultSelectedKeys={['1']}/>
                <Menu mode="inline" items={itemsLogout} style={{alignSelf:'flex-end'}}/>  
            </Sider>
            <Layout>
                <Header style={{ backgroundColor:'#FFFF', display: 'flex', alignItems: 'flex-end'  }}>
                    
                </Header>

                <Content style={{ margin: '24px 16px 0', backgroundColor:'#f0f2f5'}}>                
                    <div style={{ padding: 24, minHeight: 360, backgroundColor:'#FFFF'}}>
                        {children}
                    </div>
                </Content>
            </Layout>
        </Layout>
      </ConfigProvider>
    );
  };