import React, { useContext } from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import { ProtectedRoute } from './helpers/protectedRoutes';
import LoginPage from './pages/LoginPage';
import DashboardPage from './pages/DashboardPage';
import UserPage from './pages/UserPage';
import DigitalEmitersPage from './pages/DigitalEmitersPage';
import PermissionsPage from './pages/PermissionsPage';
import RolesPage from './pages/RolesPage';
import NotificationPage from './pages/NotificationPage';
import UserDetailsPage from './pages/UserDetailsPage';
import BackupsPage from './pages/BackupsPage';
import DashboardEmiterPage from './pages/DashboardEmiterPage';
import DigitalEmiterBillsPage from './pages/DigitalEmiterBillsPage';
import DigitalEmiterCreditNotePage from './pages/DigitalEmiterCreditNotePage';
import DigitalEmiterDebitNote from './pages/DigitalEmiterDebitNote';
import DigitalEmiterShippingOrder from './pages/DigitalEmiterShippingOrder';
import DigitalEmiterApiPage from './pages/DigitalEmiterApiPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import DigitalEmiterMassiveUpload from './pages/DigitalEmiterMassiveUpload';


function App() {
  

  return (
    <AuthProvider> 
      <HashRouter>
        <Routes>
              <Route path="/" element={ <LoginPage/>} />
              <Route path="/forgotPassword" element={ <ForgotPasswordPage/>} />
              <Route path="/dashboard" element={ <ProtectedRoute><DashboardPage/></ProtectedRoute>} />
              <Route path="/users" element={ <ProtectedRoute><UserPage/></ProtectedRoute>} />
              <Route path="/userDetail/:id" element={ <ProtectedRoute><UserDetailsPage/></ProtectedRoute>} />
              <Route path="/digitalEmiters" element={ <ProtectedRoute><DigitalEmitersPage/></ProtectedRoute>} />
              <Route path="/permissions" element={ <ProtectedRoute><PermissionsPage/></ProtectedRoute>} />
              <Route path="/roles" element={ <ProtectedRoute><RolesPage/></ProtectedRoute>} />
              <Route path="/notifications" element={ <ProtectedRoute><NotificationPage/></ProtectedRoute>} />
              <Route path="/backups" element={ <ProtectedRoute><BackupsPage/></ProtectedRoute>} />
              <Route path="/dashboardEm" element={ <ProtectedRoute><DashboardEmiterPage/></ProtectedRoute>} />
              <Route path="/digitalBillsEm" element={ <ProtectedRoute><DigitalEmiterBillsPage/></ProtectedRoute>} />
              <Route path="/digitalCNEm" element={ <ProtectedRoute><DigitalEmiterCreditNotePage/></ProtectedRoute>} />
              <Route path="/digitalDNEm" element={ <ProtectedRoute><DigitalEmiterDebitNote/></ProtectedRoute>} />
              <Route path="/digitalSO" element={ <ProtectedRoute><DigitalEmiterShippingOrder/></ProtectedRoute>} />
              <Route path="/digitalCNEm" element={ <ProtectedRoute><DigitalEmiterCreditNotePage/></ProtectedRoute>} />
              <Route path="/digitalApiKeysEm" element={ <ProtectedRoute><DigitalEmiterApiPage/></ProtectedRoute>} />
              <Route path="/massivesUploads" element={ <ProtectedRoute><DigitalEmiterMassiveUpload/></ProtectedRoute>} />
        </Routes>
    </HashRouter>
   </AuthProvider>
  );
}

export default App;
