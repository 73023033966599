import { Flex, Table, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import backendApi from '../api/backendApi'
import { DigitalBill, GetDigitalBillsRequest } from '../interfaces/AppInterfaces'

const DigitalEmiterShippingOrder = () => {
  
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [ digitalBills, setDigitalBills] = useState<DigitalBill[]>()

    const getDigitalBills = async () => {
        const getDigitalBillsRequest = await backendApi.get<GetDigitalBillsRequest>('/getEmitersCreditNotes')
        setDigitalBills(getDigitalBillsRequest.data.data[0])
        setIsLoading(false)
    }
    
    const columns =[
        {
            title: 'Numero de Control',
            dataIndex: 'control_num',
            key: 'control_num',
        },
        {
            title: 'Numero del Documento',
            dataIndex: 'doc_num',
            key: 'doc_num',
        },
        {
            title: 'Orden de Compra',
            dataIndex: 'doc_num',
            key: 'doc_num',
        },
        {
            title: 'Nombre del Cliente',
            dataIndex: 'client_full_name',
            key: 'client_full_name',
        },
        {
            title: 'Email del Cliente',
            dataIndex: 'client_email',
            key: 'client_email',
        },
        {
            title: 'Total de Orden',
            dataIndex: 'total_w_taxes',
            key: 'total_w_taxes',
        },
        {
            title: 'Total de Impuesto',
            dataIndex: 'total_tax',
            key: 'total_tax',
        },
    ]

    useEffect(() => {
        getDigitalBills()
    }, [])
    
    return (
    <Flex justify="center" vertical gap="middle">
      <Typography.Title>Ordenes de Envio</Typography.Title>  
        <Table columns ={columns} dataSource={digitalBills} scroll={{ x: 1}} loading={isLoading}/>
    </Flex>
  )
}

export default DigitalEmiterShippingOrder
