import React, { useState } from 'react'
import { Divider, Flex, Modal, Typography } from 'antd'
import sirumatekLogo from '../assets/sirumatekLogo.png';

interface Props{
    isVisible:boolean;
    isUserRegister: (val:boolean)=>{}
}

const RegisterAdminUserModal = ({isVisible, isUserRegister}:Props) => {
    
    const [show, setShow] = useState<boolean>(isVisible);
    
    const handleCancel = ()=>{
        setShow(false);
    }



    return (
    <>
      <Modal open={show} footer={null}  style={{ top: 20 }} width={900} onCancel={handleCancel}>
      <Flex align="middle" justify="center" vertical>
                <Flex align="middle" justify="center" vertical>
                    <img
                        src={sirumatekLogo}
                        width={238}
                        height={42}
                        alt="Conpany Logo"
                        style={{alignSelf:'center'}}
                        />
                    <Typography.Text style={{fontSize:14, color: 'rgba(0, 0, 0, 0.4)',alignSelf:'center',marginTop:5}}>Sistema de Imprenta Digital </Typography.Text>
                </Flex>
                    <Typography.Text style={{fontSize:18, fontWeight:'bold'}}>Registrar Usuario</Typography.Text>  
                    <Divider/>   
            </Flex>
      </Modal>
    </>
  )
}

export default RegisterAdminUserModal
