import React, { useEffect, useState } from 'react'
import { Button, Flex, Space, Table, Tag, Typography } from 'antd'
import backendApi from '../api/backendApi'
import { GetUsersResponse, UserList } from '../interfaces/AppInterfaces'
import Link from 'antd/es/typography/Link'
import {PlusOutlined} from '@ant-design/icons';
import RegisterAdminUserModal from '../components/RegisterAdminUserModal'

const UserPage = () => {
    
    const [users, setUsers] = useState<UserList[]>()
    const [loading, setLoading] = useState(true)
    const [registerUserModal, setRegisterUserModal] = useState<boolean>(false);

    const columns =[
        {
            title: 'Nombre',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Documento Identidad',
            dataIndex: 'doc_id',
            key: 'doc_id',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text:number) =>{
                switch (text) {
                    case 1:
                        return <Tag color='green'>Activo</Tag>
                        break;
                    case 2:
                        return <Tag color='red'>Inactivo</Tag>
                        break
                    default:
                        return <Tag color='grey'>N/A</Tag>
                        break;
                }
            } 
        },
        {
            title: 'Fecha Ingreso',
            dataIndex: 'created_at',
            key: 'created_at',
        },
        {
            title: 'Contacto',
            dataIndex: 'mobile_phone',
            key: 'mobile_phone',
        },     
        {
            title: 'Ultimo Inicio de Sesion',
            dataIndex: 'last_login',
            key: 'last_login',
            render: (text:string)=>{
                if (text!== null) {
                    return text;
                }
                else{
                    return 'No ha Iniciado Sesion'
                }
            }
        },    
        {
            title: 'Rol',
            dataIndex: 'rol',
            key: 'rol',
            render: (text:string) =>{
                switch (text) {
                    case 'Admin':
                        return <Tag color='blue'>{text}</Tag>
                        break;
                    case 'Manejador de Plataforma':
                        return <Tag color='volcano'>{text}</Tag>
                        break
                    case 'Emisor':
                        return <Tag color='orange'>{text}</Tag>
                        break
                    default:
                        return <Tag color='grey'>{text}</Tag>
                        break;
                }
            } 
        },
        {
            title: 'Action',
            key: 'action',
            //@ts-ignore
            render: (_, record) => (
              <Space size="middle">
                <Link style={{color:'red'}} onClick={()=>{console.log(record)}}>Inhabilitar</Link>
                <Link>Ver {record.name}</Link>
                <Link>Reestablecer Contraseña</Link>
              </Space>
            ),
          },
    ]

    const getUsers = async ()=>{
        const apiCall = await backendApi.get<GetUsersResponse>('/getUsers');
        if (apiCall.data.data) {
            setUsers(apiCall.data.data[0]);
        }
        setLoading(false)
    }

    const registerUser = ()=>{
        return setRegisterUserModal(!registerUserModal)  
    }

    useEffect(() => {
        getUsers()
    }, [])
    
  return (
    <Flex justify="center" vertical gap="middle">
        <Flex style={{width:'100%'}} justify='space-between' >
            <Typography.Title>Usuarios</Typography.Title>
            <Button type="primary" style={{background:'#47b5bd', alignSelf:'center',width:200}} onClick={registerUser}>
                <PlusOutlined /> Nuevo Usuario
            </Button>
        </Flex>
        <Table columns ={columns} dataSource={users} scroll={{ x: 1}} loading={loading}/>
        <RegisterAdminUserModal isVisible={registerUserModal} isUserRegister={()=>setRegisterUserModal}/>
    </Flex>
  )
}

export default UserPage
