import axios from "axios";
export const baseURL = 'https://sirumatek.grkzn.com/public/api/';

//export const baseURL = 'http://localhost:8000/api/';

export const backendApi = axios.create({baseURL});

backendApi.interceptors.request.use(
    
    async(config)=>{
        const token =  localStorage.getItem('token');

        if (token) {
            config.headers!['Authorization']='Bearer '+token;
        }
        return config;
    }

);

export default backendApi;
