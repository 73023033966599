import React, { useEffect, useState } from 'react'
import { Alert, Button, DatePicker, Divider, Flex, Form, Modal, Space, Table, Typography, Upload, UploadFile, UploadProps, message, notification } from 'antd'
import backendApi from '../api/backendApi'
import { FrownOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import sirumatekLogo from '../assets/sirumatekLogo.png';
import Link from 'antd/es/typography/Link';
import { GetMassivesUploadsRequest, MassivesUploads } from '../interfaces/AppInterfaces';

const DigitalEmiterMassiveUpload = () => {
  
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [newMassiveUploadModal, setNewMassiveUploadModal] = useState<boolean>(false)
    const [newMassiveUploads, setNewMassiveUploads] = useState<MassivesUploads[]>()    
    const [uploadFile, setUploadFile] = useState<UploadFile[]>();
    const [api, contextHolder] = notification.useNotification();

    const columns =[
            {
                title: 'Numero de Documentos',
                dataIndex: 'num_docs',
                key: 'num_docs',
            },
            {
                title: 'Facturas',
                dataIndex: 'num_docs_fac',
                key: 'num_docs_fac',
            },
            {
                title: 'Notas de Debito',
                dataIndex: 'num_docs_nd',
                key: 'num_docs_nd',
            },
            {
                title: 'Notas de Credito',
                dataIndex: 'num_docs_nc',
                key: 'num_docs_nc',
            },
            {
                title: 'Ver',
                key: 'watch',
                //@ts-ignore
                render: (_, record) => (
                    <Space size="middle">
                      <Link onClick={()=>console.log('D')}>Descargar</Link>
                    </Space>
                  ),
            },
    ]

    const getDigitalBills = async () => {
        const getDigitalBillsRequest = await backendApi.get<GetMassivesUploadsRequest>('/getMassivesDocs')
        setNewMassiveUploads(getDigitalBillsRequest.data.data[0])
        setIsLoading(false)
    }

    const handleChangeUploadFile: UploadProps['onChange'] = ({ fileList: newFileList }) => setUploadFile(newFileList);

    const sendMassiveUpload = async()=>{
        const form = new FormData();
        if (uploadFile) {
            form.append("upload_file", uploadFile[0].originFileObj as any );
        }
        else
        {
            api.open({
                message: 'Por Favor seleccione un Archivo',
                icon: <FrownOutlined style={{ color: '#47b5bd' }} />,
              })
        }
        const response = await backendApi.post('/submitMassivesDocs', form).then(res=>{

        }).catch(err=>{
            const result = err.response.data.message.error[0][0][0];
            //@ts-ignore
            const errors = parseComplexJSON(err.response.data.message.error[0][0][0])
          
            console.log(errors);
            
            //@ts-ignore
            openErrorNotification(errors)
        })
    }
      
    const openErrorNotification = (err:JSON) => {
       // console.log(err);
        const errors = JSON.stringify(err);
        let erFormated: any  = []

        const test = Object.entries(err).forEach(([key, value]) => { 
            erFormated.push
             (key + ' - ' + value) 
        })
        console.log(test);
        
        api.open({
          message: 'No se Registro La Carga Masiva',
          //@ts-ignore
          description: <>{erFormated.map(elem=> <><Typography.Text>{elem}</Typography.Text> <br/></> )}</>,
          icon: <FrownOutlined style={{ color: '#47b5bd' }} />,
        });
      };

      function parseComplexJSON(jsonData:any) {
        const parsedData = {};
      
        for (const key in jsonData) {
          const value = jsonData[key];
      
          if (Array.isArray(value)) {
            // Handle arrays, assuming the first element is the value
            //@ts-ignore
            parsedData[key] = value[0];
          } else if (typeof value === 'object') {
            // Recursively parse nested objects
            //@ts-ignore
            parsedData[key] = parseComplexJSON(value);
          } else {
            //@ts-ignore
            parsedData[key] = value;
          }
        }
      
        return parsedData;
      }

    useEffect(() => {
        getDigitalBills();
    }, [])
    
    return (
    <Flex justify="center" vertical gap="middle">
        {contextHolder}
    <Flex style={{width:'100%'}} justify='space-between' >
         <Typography.Title>Cargas Masivas de Documentos </Typography.Title>  
         <Button type="primary" style={{background:'#47b5bd', alignSelf:'center',width:200}} onClick={()=>setNewMassiveUploadModal(true)}>
             <PlusOutlined /> Nueva Carga Masiva
         </Button>  
    </Flex>
   
   <Table columns ={columns} dataSource={newMassiveUploads} scroll={{ x: 1}} loading={isLoading}/>

   <Modal open={newMassiveUploadModal} footer={null}  style={{ top: 20 }} width={900} onCancel={()=>setNewMassiveUploadModal(false)}>
    <Flex align="middle" justify="center" vertical>
             <Flex align="middle" justify="center" vertical>
                 <img
                     src={sirumatekLogo}
                     width={238}
                     height={42}
                     alt="Conpany Logo"
                     style={{alignSelf:'center'}}
                     />
                 <Typography.Text style={{fontSize:14, color: 'rgba(0, 0, 0, 0.4)',alignSelf:'center',marginTop:5}}>Sistema de Imprenta Digital </Typography.Text>
             </Flex>
                 <Typography.Text style={{fontSize:18, fontWeight:'bold'}}>Registrar Carga Masiva</Typography.Text>  
                 <Divider/>
                 <Form layout='vertical' style={{width:'100%'}} onFinish={sendMassiveUpload}>
                 <Flex align="middle" justify='center' style={{marginTop:5,marginBottom:30}}>
                    <Form.Item>
                        <Upload onChange={handleChangeUploadFile}  
                                beforeUpload={(file)=>{
                                        console.log(file);
                                        setUploadFile([file])
                                        return false
                                    }} 
                                accept='.xlsx'
                        >
                            <Button icon={<UploadOutlined />}>Subir Carga Masiva</Button>
                        </Upload>
                    </Form.Item>
                 </Flex>  
                     <Flex align="middle" justify='space-between' >
                        <Form.Item>
                            <Button type="primary" htmlType="submit" style={{background:'#47b5bd'}}>
                                Subir Carga Masiva
                            </Button>
                        </Form.Item>
                         <Form.Item>
                             <Button type="primary" style={{background:'#ff4d4f'}} onClick={()=>setNewMassiveUploadModal(false)}>
                                 Cancelar
                             </Button>
                         </Form.Item>
                 </Flex>
                 </Form>
         </Flex>
    </Modal>
 </Flex>
  )
}

export default DigitalEmiterMassiveUpload
