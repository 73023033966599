import React, { useContext, useEffect, useState } from 'react'
import { Card, Flex, Space, Typography, DatePicker, DatePickerProps } from 'antd'
import AuthContext from '../context/AuthContext'
import { AdminDataRequest, User } from '../interfaces/AppInterfaces'
import type { Dayjs } from 'dayjs';
import backendApi from '../api/backendApi';
import dayjs from 'dayjs';

const DashboardPage = () => {

    const [user, setUser] = useState<User>()
    const [rangeDates, setRangeDates] = useState<string[]>([])
    const [isLoading, setisLoading] = useState<boolean>(true)
    const [roles, setRoles] = useState<number>()
    const [adminUsers, setAdminUsers] = useState<number>()
    const [totalDocs,  setTotalDocs] = useState<number>()
    const [totalTaxes, setTotalTaxes] = useState<number>()
    const [digitalEmiters, setDigitalEmiters] = useState<number>()
    const [totalAnullments, setTotalAnullments] = useState<number>()
    const [massivesDocs, setMassivesDocs] = useState<number>()
    const [totalBackups, setTotalBackups] = useState<number>()





    useEffect(() => {
        const user =  localStorage.getItem('user');
        if (user) {
            setUser(JSON.parse(user));
        }
            getDashboardData()
        }, [])
    
    const dateTimeOnChange = (
        date: [any, any] | null,
        dateString: [string, string]
      ): void => {
        setisLoading(true)
        setRangeDates(dateString);
      };
    
    const getDashboardData = async()=>{
        if (rangeDates.length === 2) {
            const getDataRequest =await backendApi.post<AdminDataRequest>('/adminDashboardData',{from_date:rangeDates[0],to_date:rangeDates[1]});
            setAdminUsers(getDataRequest.data.data[0].admin_users)
            setTotalDocs(getDataRequest.data.data[0].total_docs)
            setTotalTaxes(getDataRequest.data.data[0].total_taxes)
            setDigitalEmiters(getDataRequest.data.data[0].digital_emiters)
            setTotalAnullments(getDataRequest.data.data[0].total_anullments)
            setMassivesDocs(getDataRequest.data.data[0].massives_docs)
            setTotalBackups(getDataRequest.data.data[0].total_backups)
            setRoles(getDataRequest.data.data[0].roles)
        }
        else
        {
            const toDate = dayjs();
            const toDateString = toDate.format('YYYY-MM-DD');
            const fromDate = toDate.subtract(15, 'day');
            const fromDateString = fromDate.format('YYYY-MM-DD');
            const getDataRequest =await backendApi.post<AdminDataRequest>('/adminDashboardData',{from_date:fromDateString,to_date:toDateString});
            setAdminUsers(getDataRequest.data.data[0].admin_users)
            setTotalDocs(getDataRequest.data.data[0].total_docs)
            setTotalTaxes(getDataRequest.data.data[0].total_taxes)
            setDigitalEmiters(getDataRequest.data.data[0].digital_emiters)
            setTotalAnullments(getDataRequest.data.data[0].total_anullments)
            setMassivesDocs(getDataRequest.data.data[0].massives_docs)
            setTotalBackups(getDataRequest.data.data[0].total_backups)
            setRoles(getDataRequest.data.data[0].roles)
        }
        setisLoading(false)
    }
      
    useEffect(() => {
        getDashboardData()
    }, [rangeDates])
    
return (    
    <Flex justify="center" vertical gap="middle">
        <Flex justify="space-between" gap="middle">
            <DatePicker.RangePicker  onChange={dateTimeOnChange} needConfirm defaultValue={[dayjs().subtract(15, 'day'),dayjs()]}/>
            
        </Flex>
        <Typography.Title>Bienvenido {user?.name}</Typography.Title>      
        
        <Flex gap="middle">
            <Card style={{  width: '25%', backgroundColor:'' }} loading={isLoading}>
                <Flex justify="center" vertical gap="middle" align="center">
                    <Typography style={{fontWeight:'bold', fontSize:16, color:'#8c8c8c'}}>Documentos Enviados</Typography>
                    <Typography style={{fontWeight:'bold', fontSize:14}}>{totalDocs}</Typography>
                </Flex>
            </Card>
            <Card style={{  width: '25%', backgroundColor:'RGB(198, 249, 202, 0.8)'}} loading={isLoading}>
                <Flex justify="center" vertical gap="middle" align="center">
                    <Typography style={{fontWeight:'bold', fontSize:16, color:'#8c8c8c'}}>Usuarios Admin</Typography>
                    <Typography style={{fontWeight:'bold', fontSize:14}}>{adminUsers}</Typography>
                </Flex>
            </Card>
            <Card style={{  width: '25%', backgroundColor:'RGB(198, 249, 250, 0.8)' }} loading={isLoading}>
                <Flex justify="center" vertical gap="middle" align="center">
                    <Typography style={{fontWeight:'bold', fontSize:16, color:'#8c8c8c'}}>Roles</Typography>
                    <Typography style={{fontWeight:'bold', fontSize:14}}>{roles}</Typography>
                </Flex>
            </Card>

            <Card style={{  width: '25%', backgroundColor:'RGB(198, 204, 250, 0.8)' }} loading={isLoading}>
                <Flex justify="center" vertical gap="middle" align="center">
                    <Typography style={{fontWeight:'bold', fontSize:16, color:'#8c8c8c'}}>Cargas Masivas</Typography>
                    <Typography style={{fontWeight:'bold', fontSize:14}}>{massivesDocs}</Typography>
                </Flex>
            </Card>
        </Flex>    
        
        <Flex gap="middle">
            <Card style={{  width: '25%', backgroundColor:'' }} loading={isLoading}>
                <Flex justify="center" vertical gap="middle" align="center">
                    <Typography style={{fontWeight:'bold', fontSize:16, color:'#8c8c8c'}}>Documentos Anulados</Typography>
                    <Typography style={{fontWeight:'bold', fontSize:14}}>{totalAnullments}</Typography>
                </Flex>
            </Card>
            <Card style={{  width: '25%', backgroundColor:'RGB(198, 249, 202, 0.8)'}} loading={isLoading}>
                <Flex justify="center" vertical gap="middle" align="center">
                    <Typography style={{fontWeight:'bold', fontSize:16, color:'#8c8c8c'}}>Emisores Digitales</Typography>
                    <Typography style={{fontWeight:'bold', fontSize:14}}>{digitalEmiters}</Typography>
                </Flex>
            </Card>
            <Card style={{  width: '25%', backgroundColor:'RGB(198, 249, 250, 0.8)' }} loading={isLoading}>
                <Flex justify="center" vertical gap="middle" align="center">
                    <Typography style={{fontWeight:'bold', fontSize:16, color:'#8c8c8c'}}>Impuestos Totales</Typography>
                    <Typography style={{fontWeight:'bold', fontSize:14}}>{totalTaxes} Bs</Typography>
                </Flex>
            </Card>
            <Card style={{  width: '25%', backgroundColor:'RGB(198, 204, 250, 0.8)' }} loading={isLoading}>
                <Flex justify="center" vertical gap="middle" align="center">
                    <Typography style={{fontWeight:'bold', fontSize:16, color:'#8c8c8c'}}>Documentos Respaldados</Typography>
                    <Typography style={{fontWeight:'bold', fontSize:14}}>{totalBackups}</Typography>
                </Flex>
            </Card>
        </Flex>  
        <Flex gap="middle">
            <Card style={{  width: '100%', backgroundColor:'#ffff' }} loading={isLoading}>
            </Card>
        </Flex>
    </Flex>
)
}

export default DashboardPage
