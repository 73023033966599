import React, { useEffect, useState } from 'react'
import { Card, DatePicker, Flex, Typography } from 'antd'
import backendApi from '../api/backendApi';
import dayjs from 'dayjs';
import { DashboardEmiterRequest, User } from '../interfaces/AppInterfaces';

const DashboardEmiterPage = () => {


    const [user, setUser] = useState<User>()
    const [rangeDates, setRangeDates] = useState<string[]>([])
    const [isLoading, setisLoading] = useState<boolean>(true)
    const [totalDocs,  setTotalDocs] = useState<number>()
    const [totalBase16, setTotalBase16] = useState<number>()
    const [totalBase8, setTotalBase8] = useState<number>()
    const [totalBase3, setTotalBase3] = useState<number>()
    const [totalTax16, setTotalTax16] = useState<number>()
    const [totalTax8, setTotalTax8] = useState<number>()
    const [totalTax3, setTotalTax3] = useState<number>()
    const [totalTax, setTotalTax] = useState<number>()

    const dateTimeOnChange = (
        date: [any, any] | null,
        dateString: [string, string]
      ): void => {
        setisLoading(true)
        setRangeDates(dateString);
    };

    const getDashboardData = async()=>{
        if (rangeDates.length === 2) {
            const getDataRequest =await backendApi.post<DashboardEmiterRequest>('/getDashboardEmiterData',{from_date:rangeDates[0],to_date:rangeDates[1]});
           
            setTotalDocs(getDataRequest.data.data[0].total_docs)
            setTotalBase16(getDataRequest.data.data[0].totalBase16)
            setTotalBase8(getDataRequest.data.data[0].totalBase8)
            setTotalBase3(getDataRequest.data.data[0].totalBase3)
            setTotalTax16(getDataRequest.data.data[0].totalTax16)
            setTotalTax8(getDataRequest.data.data[0].totalTax8)
            setTotalTax3(getDataRequest.data.data[0].totalTax3)
            setTotalTax(getDataRequest.data.data[0].totalTax)
           
        }
        else
        {
            const toDate = dayjs();
            const toDateString = toDate.format('YYYY-MM-DD');
            const fromDate = toDate.subtract(15, 'day');
            const fromDateString = fromDate.format('YYYY-MM-DD');   
            const getDataRequest =await backendApi.post<DashboardEmiterRequest>('/getDashboardEmiterData',{from_date:fromDateString,to_date:toDateString});
            setTotalDocs(getDataRequest.data.data[0].total_docs)
            setTotalBase16(getDataRequest.data.data[0].totalBase16)
            setTotalBase8(getDataRequest.data.data[0].totalBase8)
            setTotalBase3(getDataRequest.data.data[0].totalBase3)
            setTotalTax16(getDataRequest.data.data[0].totalTax16)
            setTotalTax8(getDataRequest.data.data[0].totalTax8)
            setTotalTax3(getDataRequest.data.data[0].totalTax3)
            setTotalTax(getDataRequest.data.data[0].totalTax)
        }
        setisLoading(false)
    }

    useEffect(() => {
    const user =  localStorage.getItem('user');
    if (user) {
        setUser(JSON.parse(user));
        getDashboardData()
    }
    else
    {
        console.log('Not user');
        
    }
    }, [])

    useEffect(() => {
        getDashboardData()
    }, [rangeDates])


    return (
    <Flex justify="center" vertical gap="middle">
    <Flex justify="space-between" gap="middle">
        <DatePicker.RangePicker  onChange={dateTimeOnChange} needConfirm defaultValue={[dayjs().subtract(15, 'day'),dayjs()]}/>
        
    </Flex>
    <Typography.Title>Bienvenido {user?.name}</Typography.Title>      
    
    <Flex gap="middle">
        <Card style={{  width: '25%', backgroundColor:'' }} loading={isLoading}>
            <Flex justify="center" vertical gap="middle" align="center">
                <Typography style={{fontWeight:'bold', fontSize:16, color:'#8c8c8c'}}>Cantidad de Documentos</Typography>
                <Typography style={{fontWeight:'bold', fontSize:14}}>{totalDocs}</Typography>
            </Flex>
        </Card>
        <Card style={{  width: '25%', backgroundColor:'RGB(198, 249, 202, 0.8)'}} loading={isLoading}>
            <Flex justify="center" vertical gap="middle" align="center">
                <Typography style={{fontWeight:'bold', fontSize:16, color:'#8c8c8c'}}>Total Base Imponible 16%</Typography>
                <Typography style={{fontWeight:'bold', fontSize:14}}>{totalBase16} Bs</Typography>
            </Flex>
        </Card>
        <Card style={{  width: '25%', backgroundColor:'RGB(198, 249, 250, 0.8)' }} loading={isLoading}>
            <Flex justify="center" vertical gap="middle" align="center">
                <Typography style={{fontWeight:'bold', fontSize:16, color:'#8c8c8c'}}>Total Base Imponible 8%</Typography>
                <Typography style={{fontWeight:'bold', fontSize:14}}>{totalBase8} Bs</Typography>
            </Flex>
        </Card>

        <Card style={{  width: '25%', backgroundColor:'RGB(198, 204, 250, 0.8)' }} loading={isLoading}>
            <Flex justify="center" vertical gap="middle" align="center">
                <Typography style={{fontWeight:'bold', fontSize:16, color:'#8c8c8c'}}>Total Base Imponible 3%</Typography>
                <Typography style={{fontWeight:'bold', fontSize:14}}>{totalBase3} Bs</Typography>
            </Flex>
        </Card>
    </Flex>    
    
    <Flex gap="middle">
        <Card style={{  width: '25%', backgroundColor:'' }} loading={isLoading}>
            <Flex justify="center" vertical gap="middle" align="center">
                <Typography style={{fontWeight:'bold', fontSize:16, color:'#8c8c8c'}}>Total Impuesto 16%</Typography>
                <Typography style={{fontWeight:'bold', fontSize:14}}>{totalTax16} Bs</Typography>
            </Flex>
        </Card>
        <Card style={{  width: '25%', backgroundColor:'RGB(198, 249, 202, 0.8)'}} loading={isLoading}>
            <Flex justify="center" vertical gap="middle" align="center">
                <Typography style={{fontWeight:'bold', fontSize:16, color:'#8c8c8c'}}>Total Impuesto 8%</Typography>
                <Typography style={{fontWeight:'bold', fontSize:14}}>{totalTax8} Bs</Typography>
            </Flex>
        </Card>
        <Card style={{  width: '25%', backgroundColor:'RGB(198, 249, 250, 0.8)' }} loading={isLoading}>
            <Flex justify="center" vertical gap="middle" align="center">
                <Typography style={{fontWeight:'bold', fontSize:16, color:'#8c8c8c'}}>Total Impuesto 3%</Typography>
                <Typography style={{fontWeight:'bold', fontSize:14}}>{totalTax3} Bs</Typography>
            </Flex>
        </Card>
        <Card style={{  width: '25%', backgroundColor:'RGB(198, 204, 250, 0.8)' }} loading={isLoading}>
            <Flex justify="center" vertical gap="middle" align="center">
                <Typography style={{fontWeight:'bold', fontSize:16, color:'#8c8c8c'}}>Total Impuesto</Typography>
                <Typography style={{fontWeight:'bold', fontSize:14}}>{totalTax} Bs</Typography>
            </Flex>
        </Card>
    </Flex>
    </Flex>
  )
}

export default DashboardEmiterPage
