import React, { useContext, useEffect, useState } from 'react'
import { Button, Checkbox, Form, Input, Layout, message , Flex, Typography, Alert, Card, Modal } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import sirumatekLogo from '../assets/sirumatekLogo.png';
import AuthContext from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';



const LoginPage = () => {

  const { signIn, errorMessage, removeError, status, user} = useContext(AuthContext);
  
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showTermsConditionsModal, setShowTermsConditionsModal] = useState<boolean>(false)
  const navigate = useNavigate();

  const onLogin = ()=>{
    setIsLoading(true);
    loginRequest()
  }

  
  const loginRequest = async ()=>{
    signIn({email,password});
  }

  useEffect(() => {        
    if (status==='autenticated') {
        setIsLoading(false);
        if (user?.role === 'Emisor') {
          navigate('/dashboardEm',{replace: true});
        }
        else
        {
          navigate('/dashboard',{replace: true});
        }
    }
  }, [status])

  const onClose = ()=>{
    removeError();
    setIsLoading(false);
  }

  return (
    <Layout>
      <Layout.Content>
        <Flex justify="center" style={{ height:'100vh'}} vertical gap="middle">
          <Flex align="middle" justify="center">
            <img
                src={sirumatekLogo}
                width={238}
                height={42}
                alt="Conpany Logo"
              />
          </Flex>
          <Flex align="middle" justify="center">
            <Typography.Text style={{fontSize:14, color: 'rgba(0, 0, 0, 0.4)'}}>Sistema de Imprenta Digital </Typography.Text>
          </Flex>
          <Flex align="middle" justify="center">
            <Typography.Text style={{fontSize:14, color: '#47b5bd'}}>Inicio de Sesion </Typography.Text>
          </Flex>
          <Flex align="middle" justify="center">
            {
                errorMessage !== '' ?
                <Alert
                    message= {errorMessage}
                    type="error"
                    closable
                    onClose={onClose}
                />
                :
                null
              }
            </Flex>
          <Flex align="middle" justify="center">
            
              <Form
                name="loginForm"
                style={{ }}
                onFinish={onLogin}
                autoComplete="off"
                size='large' 
              >
                    <Form.Item name="email"  rules={[{ required: true, message: 'Por favor Ingrese su Usuario' }]}>
                      <Input prefix={<UserOutlined style={{color:'#f58934'}}/>} placeholder='Ingrese su Usuario' size="large" value={email}
                            onChange={e => setEmail(e.target.value)} disabled={isLoading}/>
                    </Form.Item>

                    <Form.Item name="password" rules={[{ required: true, message: 'Por favor Ingrese su Contraseña' }]}>
                      <Input.Password  prefix={<LockOutlined style={{color:'#f58934'}}/>} placeholder=' Ingrese su Contraseña' size="large" value={password}
                            onChange={e => setPassword(e.target.value)} disabled={isLoading}/>
                    </Form.Item>

                    <Form.Item>
                      <Flex justify="space-between" align="center">
                        <Form.Item name="remember" valuePropName="checked" noStyle>
                          <Checkbox>Recuérdame</Checkbox>
                        </Form.Item>
                        <Typography.Link  style={{fontSize:12, color:'#47b5bd'}} onClick={()=>navigate('/forgotPassword',{replace: true})}>Olvido su contraseña?</Typography.Link >
                      </Flex>
                    </Form.Item>

                    <Form.Item>
                      <Button type="primary" htmlType="submit" style={{background:'#47b5bd'}} loading={isLoading} disabled={isLoading}>
                        Iniciar Sesion
                      </Button>
                    </Form.Item>
                </Form>
           
          </Flex>
          <Flex align="middle" justify="center">
            <Typography.Link  style={{fontSize:12}} onClick={()=>setShowTermsConditionsModal(true)}>Terminos y Condiciones </Typography.Link >
          </Flex>
        </Flex> 
      </Layout.Content>
      <Modal open={showTermsConditionsModal} footer={null}  style={{ top: 20 }} width={900} onCancel={()=>setShowTermsConditionsModal(false)}>
        <Flex align="middle" justify="center" vertical>
          <Flex align="middle" justify="center" vertical>
              <img
                  src={sirumatekLogo}
                  width={238}
                  height={42}
                  alt="Conpany Logo"
                  style={{alignSelf:'center'}}
                  />
              <Typography.Text style={{fontSize:14, color: 'rgba(0, 0, 0, 0.4)',alignSelf:'center',marginTop:5}}>Terminos Y Condiciones</Typography.Text>
          </Flex>
          <Flex align="middle" justify="center" vertical>
            <Typography.Text>Terminos Y Condiciones</Typography.Text>
          </Flex>
        </Flex>
      </Modal>
    </Layout>
  )
}

export default LoginPage