import { User } from "../interfaces/AppInterfaces";

export interface AuthState{

    status:'checking'|'autenticated'|'not-autenticated';
    token: string|null;
    user:User|null;
    errorMessage:string;
}

type AuthAction = 
    |{type:'signUp', payload:{ token:string, user:User } }
    |{type:'addError', payload:string }
    |{type:'removeError' }
    |{type:'notAutenticated' }
    |{type:'logOut' }
    |{type:'checking', payload:AuthState["status"] }

    export const authReducer = (state:AuthState,action:AuthAction):AuthState=>{

        switch (action.type) {
            case 'addError':
                
                return {...state, 
                        user:null,
                        status:'not-autenticated',
                        token:null, 
                        errorMessage:action.payload,
                       };
        
            case 'removeError':
                return{
                    ...state,
                    errorMessage:''
                };
            case 'checking':
                return{
                    ...state,
                    status:action.payload,
                };
            case 'signUp':
                return {
                    ...state,
                    errorMessage: '',
                    status: 'autenticated',
                    token: action.payload.token,
                    user: action.payload.user,
                };
            
            case 'logOut':
            case 'notAutenticated':
                return {
                    ...state,
                    status:'not-autenticated',
                    token: null,
                    user: null
                };
            
            default:
                return state;
        }
    }