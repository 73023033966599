import React, { useEffect, useState } from 'react'
import { Flex, Space, Table, Typography } from 'antd'
import { GetRolesRequest, Role } from '../interfaces/AppInterfaces'
import Link from 'antd/es/typography/Link'
import backendApi from '../api/backendApi'

const RolesPage = () => {

    const [roles, setRoles] = useState<Role[]>()
    const [loading, setLoading] = useState(true)

    const columns =[
        {
            title: 'Nombre',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Fecha de Creacion',
            dataIndex: 'created_at',
            key: 'created_at',
        },
    
        {
            title: 'Action',
            key: 'action',
            //@ts-ignore
            render: (_, record) => (
              <Space size="middle">
                <Link style={{color:'red'}} onClick={()=>{console.log(record)}}>Inhabilitar</Link>
                <Link>Detalles {record.name}</Link>
              </Space>
            ),
          },
    ]

    const getRoles = async ()=>{
        const apiCall = await backendApi.get<GetRolesRequest>('/getRoles');
        if (apiCall.data.data) {
            setRoles(apiCall.data.data[0]);
        }
        setLoading(false)
    }

    useEffect(() => {
        getRoles()
    }, [])


  return (
    <Flex justify="center" vertical gap="middle">
       <Typography.Title>Roles</Typography.Title>  
       <Table columns ={columns} dataSource={roles} scroll={{ x: 1}} loading={loading}/>
    </Flex>
  )
}

export default RolesPage
