import React, { useEffect, useState } from 'react'
import { Button, Card, Flex,  Space, Table, Tag, Typography } from 'antd'
import Link from 'antd/es/typography/Link'
import backendApi from '../api/backendApi'
import { DigitalEmiter, GetDigitalEmitersRequest } from '../interfaces/AppInterfaces'
import {PlusOutlined} from '@ant-design/icons';
import RegisterUserModal from '../components/RegisterUserModal'
import { useNavigate} from 'react-router-dom'

const DigitalEmitersPage = () => {
    const [ditalEmiters, setDigitalEmiters] = useState<DigitalEmiter[]>()
    const [loading, setLoading] = useState(true)
    const [totalDocs, setTotalDocs] = useState(0)
    const [totalAnuls, setTotalAnuls] = useState(0)
    const [registerUserModal, setRegisterUserModal] = useState<boolean>(false);
    const [isUserRegisterModal, setIsUserRegisterModal] = useState<boolean>(false);
    const navigate = useNavigate()

    const goToDetails = (id:any)=>{
        navigate('/userDetail/'+id,{replace:true})
    }
    const columns =[
        {
            title: 'Nombre',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Documento Identidad',
            dataIndex: 'doc_id',
            key: 'doc_id',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text:number) =>{
                switch (text) {
                    case 1:
                        return <Tag color='green'>Activo</Tag>
                        break;
                    case 2:
                        return <Tag color='red'>Inactivo</Tag>
                        break
                    default:
                        return <Tag color='grey'>N/A</Tag>
                        break;
                }
            } 
        },
        {
            title: 'Facturas',
            dataIndex: 'digitalBillCount',
            key: 'digitalBillCount',
        },
        {
            title: 'Notas de Credito',
            dataIndex: 'digitalCreditNoteCount',
            key: 'digitalCreditNoteCount',
        },
        {
            title: 'Notas de Debito',
            dataIndex: 'digitalDebitNoteCount',
            key: 'digitalDebitNoteCount',
        },
        {
            title: 'Ordenes de Despacho',
            dataIndex: 'digitalShippingOrderCount',
            key: 'digitalShippingOrderCount',
        },
        {
            title: 'Contacto',
            dataIndex: 'mobile_phone',
            key: 'mobile_phone',
        },     
        {
            title: 'Ultimo Inicio de Sesion',
            dataIndex: 'last_login',
            key: 'last_login',
            render: (text:string)=>{
                if (text!== null) {
                    return text;
                }
                else{
                    return 'No ha Iniciado Sesion'
                }
            }
        },    
        {
            title: 'Action',
            key: 'action',
            //@ts-ignore
            render: (_, record) => (
              <Space size="middle">
                <Link style={{color:'red'}} onClick={()=>{console.log(record)}}>Inhabilitar</Link>
                <Link onClick={()=>goToDetails(record.id)}>Ver {record.name}</Link>
                <Link>Reestablecer Contraseña</Link>
              </Space>
            ),
        },
    ]


    const getDigitalEmiters = async ()=>{
        const apiCall = await backendApi.get<GetDigitalEmitersRequest>('/getDigitalEmiters');
        if (apiCall.data.data) {
            setDigitalEmiters(apiCall.data.data[0]);
            getCardData(apiCall.data.data[0]);
        }
        setLoading(false);
    }

    const getCardData = (ditalEmiters:DigitalEmiter[]) =>{
        let totalDocs = 0;
        let totalAnuls = 0;
        
        ditalEmiters.map(elm=>{
            totalDocs += elm.digitalBillCount;
            totalDocs += elm.digitalCreditNoteCount;
            totalDocs += elm.digitalDebitNoteCount;
            totalDocs += elm.digitalShippingOrderCount;
            totalAnuls+= elm.digitalCreditNoteCount;
            totalAnuls+= elm.digitalDebitNoteCount;
        });
        
        
        setTotalDocs(totalDocs);
        setTotalAnuls(totalAnuls);
    }

    const registerDigitalEmiter = ()=>{
        return setRegisterUserModal(!registerUserModal)
        
    }

    useEffect(() => {
        getDigitalEmiters()
    }, [])

    useEffect(() => {
        console.log(isUserRegisterModal)
    }, [isUserRegisterModal])
    

  return (
    <Flex justify="center" vertical gap="middle">
        <Flex style={{width:'100%'}} justify='space-between' >
            <Typography.Title>Emisores Digitales</Typography.Title>
            <Button type="primary" style={{background:'#47b5bd', alignSelf:'center',width:200}} onClick={registerDigitalEmiter}>
                <PlusOutlined /> Nuevo Emisor
            </Button>
        </Flex>
        <Flex justify="center"gap="middle">
            <Flex gap="middle" justify="center" style={{ width:'100%'}}>
                <Card style={{  width: '40%', backgroundColor:'RGB(198, 249, 202, 0.8)'}} loading={loading} >
                    <Flex justify="center"gap="middle" vertical align='center'>
                        <Typography.Text style={{fontSize:15,  color:'#8c8c8c', fontWeight:'bold'}}>Emisores Digitales</Typography.Text>
                        <Typography.Text style={{fontSize:18, fontWeight:'bold' }}>{ditalEmiters?.length}</Typography.Text>
                    </Flex>
                </Card>
                <Card style={{  width: '40%', backgroundColor:'RGB(198, 249, 250, 0.5)' }} loading={loading} >
                    <Flex justify="center"gap="middle" vertical align='center'>
                        <Typography.Text style={{fontSize:15,  color:'#8c8c8c', fontWeight:'bold'}}>Documentos Transmitidos</Typography.Text>
                        <Typography.Text style={{fontSize:18, fontWeight:'bold' }}>{totalDocs}</Typography.Text>
                    </Flex>
                </Card>
                <Card style={{  width: '40%', backgroundColor:'RGB(198, 204, 250, 0.5)' }} loading={loading} >
                    <Flex justify="center"gap="middle" vertical align='center'>
                        <Typography.Text style={{fontSize:15,  color:'#8c8c8c', fontWeight:'bold'}}>Documentos Anulados</Typography.Text>
                        <Typography.Text style={{fontSize:18, fontWeight:'bold' }}>{totalAnuls}</Typography.Text>
                    </Flex>
                </Card>
            </Flex>    
        </Flex>
      <Flex justify="center" vertical gap="middle">
        <Table columns ={columns} dataSource={ditalEmiters} scroll={{ x: 1}} loading={loading}/>
        
      </Flex>
      <RegisterUserModal isVisible={registerUserModal} isUserRegister={()=>setIsUserRegisterModal}/>
    </Flex>
  )
}

export default DigitalEmitersPage
