import React, { useEffect, useState } from 'react'
import { Flex, Space, Table, Typography } from 'antd'
import Link from 'antd/es/typography/Link'
import backendApi from '../api/backendApi'

const NotificationPage = () => {

    const [notifications, setNotifications] = useState()
    const [isLoading, setIsLoading] = useState<boolean>(true)

    const columns = [
      {
        title: 'Nombre',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Cantidad de Usuarios Notificados',
        dataIndex: 'num_notificated',
        key: 'num_notificated',
      },
      {
        title: 'Usuario Notificado',
        dataIndex: 'recipient',
        key: 'recipient',
      },
      {
        title: 'Action',
        key: 'action',
        //@ts-ignore
        render: (_, record) => (
          <Space size="middle">
            <Link>Ver Notificacion</Link>
          </Space>
        ),
    },
    ]

    const getNotifications = async () => {
      const getNotificationRequest = await backendApi.get('/getAllNotifications')
      setIsLoading(false)
    }

    useEffect(() => {
      getNotifications()
    }, [])
    
  return (
    <Flex justify="center" vertical gap="middle">
      <Typography.Title>Notificaciones</Typography.Title>  
      <Table columns ={columns} dataSource={notifications} scroll={{ x: 1}} loading={isLoading}/>
    </Flex>
  )
}

export default NotificationPage
